export const SampleOrderFormValues = {
  rememberSiteAndRequester: false,
  currentDate: null,
  projects: null,
  customNotificationLogic: false,
  patientEmailNotificationTestIds: [],
  patientSMSNotificationTestIds: [],
  providerEmailNotificationTestIds: [],
  providerSMSNotificationTestIds: [],
  patientUpdateStatus: "ADD",
  referralItems: [],
  referralOrganizations: null,
  referralReasons: null,
  sampleTypes: null,
  sampleXML: "",
  patientProperties: {
    currentDate: "",
    patientLastUpdated: "",
    personLastUpdated: "",
    patientUpdateStatus: "ADD",
    patientPK: "",
    stnumber: null,
    subjectNumber: "",
    nationalId: "",
    guid: "",
    lastName: "",
    firstName: "",
    aka: null,
    mothersName: null,
    mothersInitial: null,
    streetAddress: "",
    city: "",
    commune: "",
    addressDepartment: null,
    gender: "",
    ageYears: null,
    ageMonths: null,
    ageDays: null,
    birthDateForDisplay: "",
    patientType: "",
    insuranceNumber: null,
    occupation: null,
    primaryPhone: "",
    email: null,
    healthRegion: "",
    education: "",
    maritialStatus: "",
    nationality: "",
    healthDistrict: "",
    otherNationality: "",
    patientContact: {
      person: {
        lastName: "",
        firstName: "",
        email: "",
        primaryPhone: "",
      },
    },
    readOnly: false,
    patientIdentities: null,
  },
  patientSearch: null,
  patientEnhancedSearch: null,
  patientClinicalProperties: null,
  sampleOrderItems: {
    newRequesterName: "",
    orderTypes: null,
    orderType: null,
    externalOrderNumber: null,
    labNo: "",
    requestDate: "",
    receivedDateForDisplay: "",
    receivedTime: "",
    nextVisitDate: "",
    requesterSampleID: null,
    referringPatientNumber: null,
    referringSiteId: "",
    referringSiteDepartmentId: "",
    referringSiteCode: null,
    referringSiteName: "",
    referringSiteDepartmentName: null,
    referringSiteList: null,
    referringSiteDepartmentList: null,
    providersList: null,
    providerId: null,
    providerPersonId: null,
    providerFirstName: "",
    providerLastName: "",
    providerWorkPhone: "",
    providerFax: "",
    providerEmail: "",
    facilityAddressStreet: null,
    facilityAddressCommune: null,
    facilityPhone: null,
    facilityFax: null,
    paymentOptionSelection: "",
    paymentOptions: null,
    modified: true,
    sampleId: "",
    readOnly: false,
    billingReferenceNumber: "",
    testLocationCode: "",
    otherLocationCode: "",
    testLocationCodeList: null,
    program: "",
    programList: null,
    contactTracingIndexName: null,
    contactTracingIndexRecordNumber: null,
    priorityList: null,
    priority: "ROUTINE",
    programId: null,
    additionalQuestions: null,
  },
  initialSampleConditionList: [],
  sampleNatureList: null,
  testSectionList: null,
  warning: false,
  useReferral: false,
  rejectReasonList: null,
};

export const ReferralItem = {
  referralId: null,
  accessionNumber: null,
  sampleType: null,
  referringTestName: "",
  referralResults: "",
  referralStatus: null,
  referralDate: null,
  referrer: "",
  referredInstituteId: "",
  referredTestId: "",
  referredTestIdShadow: null,
  testSelectionList: null,
  referredResult: "",
  referredDictionaryResult: null,
  referredMultiDictionaryResult: null,
  referredResultType: "",
  dictionaryResults: [],
  referredSendDate: "",
  referredReportDate: null,
  pastNotes: null,
  note: null,
  additionalTestsXMLWad: null,
  referralReasonId: "",
  referralResultId: null,
  modified: false,
  additionalTests: null,
  inLabResultId: null,
  multiSelectResultValues: null,
  qualifiedResultValue: null,
};

export const ModifyOrderFormValues = {
  patientName: "",
  dob: "",
  gender: "",
  nationalId: "",
  accessionNumber: "",
  newAccessionNumber: "",
  existingTests: [
    {
      accessionNumber: null,
      analysisId: null,
      sampleType: null,
      testName: null,
      sampleItemId: null,
      testId: null,
      canCancel: false,
      canceled: false,
      add: false,
      status: null,
      sortOrder: null,
      canRemoveSample: false,
      removeSample: false,
      collectionDate: null,
      collectionTime: null,
      sampleItemChanged: false,
      hasResults: false,
    },
  ],
  possibleTests: [
    {
      accessionNumber: null,
      analysisId: null,
      sampleType: null,
      testName: null,
      sampleItemId: null,
      testId: null,
      canCancel: false,
      canceled: false,
      add: false,
      status: null,
      sortOrder: null,
      canRemoveSample: false,
      removeSample: false,
      collectionDate: null,
      collectionTime: null,
      sampleItemChanged: false,
      hasResults: false,
    },
  ],
  rememberSiteAndRequester: false,
  currentDate: null,
  projects: null,
  customNotificationLogic: false,
  patientEmailNotificationTestIds: [],
  patientSMSNotificationTestIds: [],
  providerEmailNotificationTestIds: [],
  providerSMSNotificationTestIds: [],
  patientUpdateStatus: "ADD",
  referralItems: [],
  referralOrganizations: null,
  referralReasons: null,
  sampleTypes: null,
  sampleXML: "",
  patientSearch: null,
  patientEnhancedSearch: null,
  patientClinicalProperties: null,
  sampleOrderItems: {
    newRequesterName: "",
    orderTypes: null,
    orderType: null,
    externalOrderNumber: null,
    labNo: "",
    requestDate: "",
    receivedDateForDisplay: "",
    receivedTime: "",
    nextVisitDate: "",
    requesterSampleID: null,
    referringPatientNumber: null,
    referringSiteId: "",
    referringSiteDepartmentId: "",
    referringSiteCode: null,
    referringSiteName: "",
    referringSiteDepartmentName: null,
    referringSiteList: null,
    referringSiteDepartmentList: null,
    providersList: null,
    providerId: null,
    providerPersonId: null,
    providerFirstName: "",
    providerLastName: "",
    providerWorkPhone: "",
    providerFax: "",
    providerEmail: "",
    facilityAddressStreet: null,
    facilityAddressCommune: null,
    facilityPhone: null,
    facilityFax: null,
    paymentOptionSelection: "",
    paymentOptions: null,
    modified: true,
    sampleId: "",
    readOnly: false,
    billingReferenceNumber: "",
    testLocationCode: "",
    otherLocationCode: "",
    testLocationCodeList: null,
    program: "",
    programList: null,
    contactTracingIndexName: null,
    contactTracingIndexRecordNumber: null,
    priorityList: null,
    priority: "ROUTINE",
    programId: null,
    additionalQuestions: null,
  },
  initialSampleConditionList: [],
  sampleNatureList: null,
  testSectionList: null,
  warning: false,
  useReferral: false,
  rejectReasonList: null,
};
